// common configuration
const config = {
    api: {
        baseUrl: "https://sales.treserviselektro.cz/api"
    },
    afterLoginRoute: "dashboard",
    vueAppUrl: "https://sales.treserviselektro.cz"
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:10041";
    config["api"]["baseUrl"] = "http://localhost:10040";
}

export const CONFIG = config;