<template>
    <div class="template_with_header">
        <div class="row text-center align-items-start justify-content-center h-100">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div v-if="nameEdit" class="w-100">
                    <input type="text" class="form-control mb-2"
                    v-model="template.name"
                    v-on:focusout="saveName"
                    :ref="popupId + '-name'">
                </div>
                <h3 @click="editName" class="clickable-green" v-else>{{ template.name }}</h3>
            </div>
            <div class="template col-12">
                <div class="row">
                    <div class="col-12 text-start">
                        <span v-if="template.files.length && isAnyUnchecked"
                            class="check-btn"
                            @click="selectAll">{{ $t("utils.selectAll") }}</span>
                        <span v-if="template.files.length && isAnyChecked"
                            class="check-btn"
                            :class="{'ms-4': isAnyUnchecked}"
                            @click="deselectAll">{{ $t("utils.deselectAll") }}</span>
                    </div>
                    <File
                        v-for="(file, i) in template.files"
                        :key="'file' + i"
                        :ref="'file' + popupId + i"
                        class="col-12"
                        :file="file"
                        :position="i"
                        :first="i == 0"
                        :last="i + 1 == template.files.length"
                        @elementUp="up"
                        @elementDown="down"
                    />
                    <div v-if="template.files.length == 0">
                        <span>{{ $t("template.noFiles") }}</span>
                    </div>
                </div>
                <hr width="100% my-2">
                <div class="row">
                    <div class="btn btn-primary col-12 d-flex justify-content-center align-items-center" data-bs-toggle="modal" :data-bs-target="'#upload-files'" @click="updateTemplate">
                        <icon icon="Plus" class="me-1"/>{{ $t("utils.addFile") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import File from "@/components/File.vue";
import Popup from "@/components/Popup.vue";
export default {
    components: {
        File,
        Popup
    },
    props: {
        template: {
            type: Object,
            required: false,
            default: {}
        },
        position: {
            type: Number,
            required: true
        }
    },
    created: function () {
        this.fileNameCreate();
    },
    data() {
        return {
            popupId: this.makeid(20),
            newFiles: null,
            fileName: null,
            nameEdit: false
        }
    },
    computed: {
        isAnyChecked: function () {
            for (var i = 0; i < this.template.files.length; i++){
                if (this.template.files[i].checked) {
                    return true;
                }
            }
            return false;
        },
        isAnyUnchecked: function () {
            for (var i = 0; i < this.template.files.length; i++){
                if (!this.template.files[i].checked) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        fileNameCreate: function () {
            if (this.template.name == this.$t("template.empty")) {
                this.fileName = null;
                return;
            }
            this.fileName = this.template.name;
        },
        selectAll: function () {
            for (var i = 0; i < this.template.files.length; i++){
                this.template.files[i].checked = true;
            }
        },
        deselectAll: function () {
            for (var i = 0; i < this.template.files.length; i++){
                this.template.files[i].checked = false;
            }
        },
        editName: function () {
            this.nameEdit = true;
            setTimeout(function () { this.$refs[this.popupId + '-name'].focus() }.bind(this), 100);
        },
        saveName: function () {
            this.nameEdit = false;
            if (!this.template.name) {
                this.template.name = this.$t("template.empty");
            }
            this.fileNameCreate();
        },
        up: function (position) {
            this.template.files = this.moveElement(this.template.files, position, position - 1);
        },
        down: function (position) {
            this.template.files = this.moveElement(this.template.files, position, position + 1);
        },
        moveElement: function(arr, fromIndex, toIndex) {
            return arr.map((item, index) => {
                if (index === toIndex) return arr[fromIndex];
                if (index === fromIndex) return arr[toIndex];
                return item;
            });
        },
        makeid: function(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
            }
            return result;
        },
        updateTemplate: function () {
            this.$store.commit("addingToTemplate", this.position)
        }
    }
}
</script>


<style lang="scss" scoped>
h3 {
    font-size: 24px;
}
</style>