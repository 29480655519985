<template>
    <div>
        <div class="d-flex align-items-center flex-column mb-4 pt-4">
            <h2 class="d-flex flex-row align-items-center mb-4">
                <span>Výběr scény</span>
                <div class="d-flex align-items-center justify-content-center ms-3">
                    <div class="btn base-btn" @click="sceneAdd">{{ $t("scene.add") }}</div>
                </div>
            </h2>
            <div class="scenes" ref="scenes">
                <span @click="selectScene(i)" v-for="(scene, i) in scenes" class="btn base-btn mx-2" :class="{'selectedScene': i == selectedSceneId }">
                    {{ scene.name }}
                </span>
            </div>
        </div>
        <Scene :scene="selectedScene" v-if="selectedScene"/>

        <Popup :header="$t('file.preview')" :editable="false" id="file-popup" :saveButton="false" class="file-popup">
            <template v-slot:body>
                <embed :src="$store.getters.file" width="100%" height="100%"/>
            </template>
        </Popup>
        <Popup :header="$t('template.addFileHeader')" :button="$t('template.uploadData')" :editable="true" id="upload-files" @save-data="uploadFiles">
            <template v-slot:editor>
                <input type="file" class="form-control" accept=".pdf" @change="changeFiles" multiple>
            </template>
        </Popup>
    </div>
</template>

<script>
import Scene from "@/components/Scene.vue";
import Popup from "@/components/Popup.vue";
export default {
    components: {
        Scene,
        Popup
    },
    data() {
        return {
            scenes: [],
            selectedScene: null,
            selectedSceneId: null,
            newFiles: null
        }
    },
    created: function(){
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get("/scenes",
            ).then(
                (response) => {
                    this.scenes = response.data;
                    if (this.scenes && this.scenes.length >= 1) {
                        this.selectScene(0);
                    }
                    else {
                        this.sceneAdd();
                    }
                });
        },
        selectScene: function (i) {
            this.selectedScene = this.scenes[i];
            this.selectedSceneId = i;
        },
        sceneAdd: function () {
            this.scenes.push({ "name": this.$t("scene.empty"), "templates": [
                {
                    "name": this.$t("template.empty"),
                    "files": []
                }]
            });
            this.selectScene(this.scenes.length - 1);
        },
        changeFiles(event) {
            this.newFiles = event.target.files;
        },
        uploadFiles: function () {
            for (var i = 0; i < this.newFiles.length; i++){
                var file = this.newFiles[i];
                this.getBase64(file).then(function (data) {
                    this.selectedScene.templates[this.$store.getters.templateId].files.push({
                        "name": data.name,
                        "file": data.file,
                        "system": false,
                        "checked": true
                    })
                }.bind(this));
            }
            this.newFiles = null;
        },
        getBase64: function (file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve({ "file": reader.result, "name": file.name.substring(0, file.name.length - 4) });
                reader.onerror = error => reject(error);
            });
        },
    }
}
</script>
<style lang="scss">
</style>