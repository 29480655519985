<template>
    <div class="row text-center align-items-center justify-content-center">
        <div class="col-12 d-flex align-items-center justify-content-center flex-column mb-3">
            <div class="d-flex flex-row w-100 align-items-center justify-content-center">
                <h2 class="d-flex flex-row">
                    <span class="me-2">{{ $t("scene.selected") }}</span>
                    <div v-if="nameEdit" class="d-flex align-items-center justify-content-center">
                        <input type="text" class="form-control mb-2 scene-name-edit"
                        v-model="scene.name"
                        v-on:focusout="saveName"
                        :ref="popupId + '-name'">
                    </div>
                    <span v-else @click="editName" class="clickable-green">{{ scene.name }}</span>
                </h2>
            </div>
            <div class="my-2 btn save-button base-btn d-flex align-items-center justify-content-center flex-row" data-bs-toggle="modal" :data-bs-target="'#' + popupId + '-download'">
                <icon icon="FileDownload" class="me-1"/>
                <span>{{ $t("utils.download") }}</span>
            </div>
        </div>
        <div class="col-12 templates mb-3" ref="templates">
            <div
                v-for="(template, i) in scene.templates"
                :key="'template_' + i"
                class="row align-items-center justify-content-center me-5 complete-template">
                <div class="col-11 d-flex flex-column justify-content-start align-items-center h-100">
                    <div>
                        <icon icon="ArrowLeft" v-if="i == 0" class="not-clickable"></icon>
                        <icon icon="ArrowLeft" v-else @click="left(i)"
                            class="clickable-green"/>
                        <icon icon="ArrowRight" v-if="i == scene.templates.length - 1" class="ms-2 not-clickable"/>
                        <icon icon="ArrowRight" v-else class="ms-2 clickable-green"
                        @click="right(i)"/>
                    </div>
                    <Template :template="template" :position="i"/>
                </div>
                <div @click="addEmpty(i + 1)" class="col-1 d-flex">
                    <div class="btn base-btn">
                        <icon icon="Plus"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Popup :header="$t('template.fileHeader')" :editable="true" :id="popupId + '-download'" @save-data="save">
        <template v-slot:editor>
            <span><b>{{ $t('template.fileTitle') }}</b></span>
            <input type="text" class="form-control" :placeholder="$t('template.filePlaceholder')" v-model="fileName">
        </template>
    </Popup>
    <LoadingSpinner :loading="loading"/>
</template>

<script>

import Template from "@/components/Template.vue";
import Popup from "@/components/Popup.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
    components: {
        Template,
        Popup,
        LoadingSpinner
    },
    props: {
        scene: {
            type: Object,
            required: false,
            default: {}
        },
    },
    data() {
        return {
            popupId: this.makeid(20),
            newFile: null,
            fileName: null,
            nameEdit: false,
            loading: false
        }
    },
    created: function () {
        if (!this.scene.templates.length) {
            this.addEmpty(0);
        }
        this.fileNameCreate();

    },
    methods: {
        editName: function () {
            this.nameEdit = true;
            setTimeout(function () { this.$refs[this.popupId + '-name'].focus() }.bind(this), 100);
        },
        saveName: function () {
            this.nameEdit = false;
            if (!this.scene.name) {
                this.scene.name = this.$t("scene.empty");
            }
            this.fileNameCreate();
        },
        fileNameCreate: function () {
            if (this.scene.name == this.$t("scene.empty")) {
                this.fileName = null;
                return;
            }
            this.fileName = this.scene.name;
        },
        addEmpty: function (position) {
            this.scene.templates.splice( position, 0,
                {
                    "name": this.$t("template.empty"),
                    "files": []
                })
        },
        moveElement: function(arr, fromIndex, toIndex) {
            return arr.map((item, index) => {
                if (index === toIndex) return arr[fromIndex];
                if (index === fromIndex) return arr[toIndex];
                return item;
            });
        },
        left: function (position) {
            this.scene.templates = this.moveElement(this.scene.templates, position, position - 1);
        },
        right: function (position) {
            this.scene.templates = this.moveElement(this.scene.templates, position, position + 1);
        },
        makeid: function(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
            }
            return result;
        },
        save: function () {
            this.loading = true;
            var files = []
            for (var i = 0; i < this.scene.templates.length; i++) {
                var template = this.scene.templates[i];
                for (var j = 0; j < template.files.length; j++) {
                    var file = template.files[j];
                    if (!file.checked) {
                        continue;
                    }
                    files.push(file);
                }
            }

            this.$store.getters.api.post('/pdf/merge', files,
            {
                responseType: 'blob'
            }
            ).then(function (response) {
                const href = URL.createObjectURL(response.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                var name = this.fileName;
                if (!name) {
                    name = 'merged';
                }

                link.setAttribute('download', name + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                this.fileNameCreate();
                this.loading = false;
            }.bind(this));
        }
    }
}
</script>

<style lang="scss">
.save-button {
    max-width: 200px;
    width: 100%;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-items: center;
}

.scene-name-edit {
    max-width: 500px;
    width: 100%;
}
</style>