<template>
    <div class="file mt-2">
        <div class="row justify-content-start align-items-center text-start">
            <div class="col-1">
                <input type="checkbox" class="form-check-input mx-3 clickable" v-model="file.checked">
            </div>
            <div class="col-9">
                <span data-bs-toggle="modal" :data-bs-target="'#file-popup'" @click="setFile" class="clickable"><b>{{ file.name }}</b></span>
            </div>
            <div class="col-2">
                <icon icon="ArrowUp" v-if="!first" @click="$emit('elementUp', position)" class="clickable"/>
                <icon icon="ArrowDown" v-if="!last" :class="{'ms-2': !first}" class="clickable" @click="$emit('elementDown', position)"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: Object,
            required: false,
            default: {}
        },
        position: {
            type: Number,
            required: true
        },
        first: {
            type: Boolean,
            required: true
        },
        last: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
        }
    },
    created: function () {
        if (!this.file.checked) {
            this.file.checked = false;
        }
    },
    methods: {
        setFile: function () {
            this.$store.commit("updateFile", this.file);
        }
    }
}
</script>


<style lang="scss">
</style>