<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-down" width="12" height="20" viewBox="6 4 10 20" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="16" y1="15" x2="12" y2="19" />
    <line x1="8" y1="15" x2="12" y2="19" />
  </svg>
</template>

<script>
  export default {}
</script>

<style>

</style>